import { defineStore } from 'pinia'
import { IImState } from '@/interface'
import * as RongIMLib from '@rongcloud/imlib-next'
import { rongImService } from '@/api'
import defaultAvatar from '@/assets/images/default-avatar.png'

const appkey: any = process.env.VUE_APP_RONGIM_KEY

const assginUserInfo = (obj, key, user) => {
  const item = user[key]
  if (item && item.address) {
    return {
      ...obj,
      userName: item?.userName || `NFT member #${item?.address.slice(-4)}`,
      pic: item?.pic || defaultAvatar,
      address: item?.address || '',
    }
  }
  return {
    ...obj,
    userName: item?.userName || 'NFT member',
    pic: item?.pic || defaultAvatar,
    address: item?.address || '',
  }
}

export const useImStore = defineStore('im', {
  state: (): IImState => ({
    RongIMLib,
    RongIMShow: false,
    RongConnectStatus: false,
    conversationList: [],
    targetId: null,
    targetConversationType: null,
    targetHistory: [],
    targetHasMore: false,
    isNFT: false,
    userInfo: null,
    totalUnreadCount: 0,
  }),
  actions: {
    /**
     * init
     * @return {*}
     */
    initAction(): Promise<void> {
      return new Promise((resolve, reject) => {
        try {
          RongIMLib.init({ appkey, navigators: ['https://navsg01.cn.ronghub.com'] })
          console.log('RongIMLib init success')

          // 添加事件监听
          const { Events } = RongIMLib

          RongIMLib.addEventListener(Events.CONNECTING, () => {
            console.log('正在链接服务器')
          })

          RongIMLib.addEventListener(Events.CONNECTED, () => {
            console.log('已经链接到服务器')
          })

          RongIMLib.addEventListener(Events.MESSAGES, ({ messages }: any): any => {
            console.log('获取新消息成功=============', messages)
            setTimeout(async () => {
              await this.getUserInfoAction()
              await this.getConversationListAction()
              if (this.targetId) {
                messages.forEach(item => {
                  if (item.targetId === this.targetId) {
                    this.targetHistory?.push(assginUserInfo(item, item.senderUserId, this.userInfo))
                  }
                })
              }
            }, 300)
          })
          resolve()
        } catch (error) {
          console.error('RongIMLib init error')
          reject(error)
        }
      })
    },

    /**
     * 连接Im
     */
    connectAction(): Promise<void> {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await rongImService.getUseRongCloudToken()
          this.isNFT = !!result.length
          if (this.isNFT) {
            RongIMLib.connect(result).then(async (res: any) => {
              const { code, data } = res
              if (code === RongIMLib.ErrorCode.SUCCESS) {
                RongIMLib.addEventListener(RongIMLib.Events.MESSAGES, () => {
                  console.log('connectAction获取新消息成功调取后更新未读会话=============')
                  this.getTotalUnreadCount()
                })
                await this.getUserInfoAction()
                console.log('链接成功, 链接用户 id 为: ', data.userId)
                resolve()
              } else if (code === RongIMLib.ErrorCode.RC_CONN_USER_OR_PASSWD_ERROR) {
                this.connectAction()
              } else {
                console.error('链接失败, code:', code)
                reject()
              }
            })
          } else {
            resolve()
          }
        } catch (error) {
          console.error('RongIMLib connect error')
          reject(error)
        }
      })
    },

    /**
     * 获取所有会话未读数
     */
    getTotalUnreadCount(): void {
      const includeMuted = false
      const conversationTypes = [
        RongIMLib.ConversationType.PRIVATE,
        RongIMLib.ConversationType.GROUP,
      ]
      RongIMLib.getTotalUnreadCount(includeMuted, conversationTypes)
        .then(res => {
          if (res.code === 0) {
            console.log('未读消息数', res.data)
            this.totalUnreadCount = res.data
          } else {
            console.log(res.code, res.msg)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    /**
     * 获取会话列表
     *
     * @return {*}
     */
    getConversationListAction(): Promise<void> {
      return new Promise((resolve, reject) => {
        try {
          RongIMLib.getConversationList({
            count: 1000,
          }).then(async ({ code, data: conversationList }: any) => {
            if (code === 0) {
              const result = conversationList
                .sort((a, b) => {
                  return b.conversationType - a.conversationType
                })
                .map(k => assginUserInfo(k, k.targetId, this.userInfo))
              this.conversationList = result
              console.log('获取会话列表成功', result)
              resolve(result)
            } else {
              console.log('获取会话列表失败: ', code)
              reject()
            }
          })
        } catch (error) {
          console.error('RongIMLib get conversationList error', error)
        }
      })
    },

    /**
     * 选择好友聊天
     *
     * @param {*} targetId
     * @return {*}  {Promise<void>}
     */
    chioceTargetAction({ targetId, conversationType }: any): Promise<void> {
      return new Promise((resolve, reject) => {
        try {
          console.log('chioceTargetAction', targetId, conversationType)
          if (targetId !== this.targetId) {
            this.targetHistory = []
            this.targetHasMore = false
            this.targetId = targetId
            this.targetConversationType = conversationType
          }
          resolve()
        } catch (error) {
          reject()
        }
      })
    },

    /**
     * 获取当前会话历史记录
     *
     */
    getHistoryMessagesAction(option): Promise<void> {
      return new Promise((resolve, reject) => {
        try {
          const conversation: any = {
            conversationType: this.targetConversationType,
            targetId: this.targetId,
          }

          RongIMLib.getHistoryMessages(conversation, option).then(async (res: any) => {
            if (res.code === 0) {
              const { list, hasMore } = res.data

              this.targetHistory = [
                ...list.map(k => assginUserInfo(k, k.senderUserId, this.userInfo)),
                ...this.targetHistory,
              ]
              this.targetHasMore = hasMore
              console.log('获取历史记录成功', this.targetHistory)
              resolve()
            } else {
              console.error('RongIMLib getHistoryMessages error', res)
              reject()
            }
          })
        } catch (error) {
          console.error('RongIMLib getHistoryMessages error', error)
          reject(error)
        }
      })
    },
    /**
     * 发送消息
     *
     * @param {*} { }
     */
    sendMessageAction(message, options = {}): Promise<void> {
      return new Promise((resolve, reject) => {
        try {
          const conversation: any = {
            conversationType: this.targetConversationType,
            targetId: this.targetId,
          }
          RongIMLib.sendMessage(conversation, message, options).then(res => {
            if (res.code === RongIMLib.ErrorCode.SUCCESS) {
              const item: any = res.data
              this.targetHistory.push(assginUserInfo(item, item.targetId, this.userInfo))
              console.log('消息发送成功', res.data)
              resolve()
            } else {
              console.log('消息发送失败', res.code, res.msg)
              reject()
            }
          })
        } catch (error) {
          console.error('RongIMLib sendMessageAction error', error)
          reject(error)
        }
      })
    },
    /**
     * 消息已读
     *
     */
    clearMessagesUnreadStatusAction(): Promise<void> {
      return new Promise((resolve, reject) => {
        try {
          const conversation: any = {
            conversationType: this.targetConversationType,
            targetId: this.targetId,
          }
          RongIMLib.clearMessagesUnreadStatus(conversation).then(res => {
            if (res.code === 0) {
              this.getConversationListAction()
              this.getTotalUnreadCount()
              console.log('设置会话消息已读')
              resolve()
            } else {
              console.error('RongIMLib clearMessagesUnreadStatusAction error')
              reject()
            }
          })
          resolve()
        } catch (error) {
          console.error('RongIMLib clearMessagesUnreadStatusAction error', error)
          reject(error)
        }
      })
    },
    /**
     * 初始化message
     *
     * @param {*} { commit }
     * @return {*}
     */
    initMessageAction(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.targetHistory = []
        this.targetHasMore = false
        resolve()
      })
    },
    /**
     * 初始化target
     *
     * @param {*} { commit }
     * @return {*}
     */
    initTargetAction(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.targetHistory = []
        this.targetHasMore = false
        this.targetId = null
        this.targetConversationType = null
        resolve()
      })
    },

    changeRongConnectStatus() {
      this.RongConnectStatus = true
    },

    /**
     * 设置会话置顶
     * @param {*} { }
     * @return {*}
     */
    setConversationToTopAction({ conversationType, targetId }): Promise<void> {
      return new Promise((resolve, reject) => {
        RongIMLib.setConversationToTop({ conversationType, targetId }, true).then(({ code }) => {
          if (!code) {
            resolve()
          }
        })
      })
    },
    /**
     * 退出Rong Im
     *
     * @return {*}  {Promise<void>}
     */
    logoutAction(): Promise<void> {
      return new Promise((resolve, reject) => {
        RongIMLib.disconnect().then(() => {
          console.log('成功断开')
          this.RongIMShow = false
          this.RongConnectStatus = false
          this.isNFT = false
          this.userInfo = null
          this.targetId = null
          this.targetConversationType = null
          this.targetHistory = []
          this.targetHasMore = false
          resolve()
        })
      })
    },

    /**
     * 获取好友列表
     *
     */
    getUserInfoAction(): Promise<void> {
      return new Promise(async (resolve, reject) => {
        try {
          const userInfo = await rongImService.getRelation()
          this.userInfo = userInfo
          resolve()
        } catch (error) {
          console.error('getUserInfoAction error')
          reject()
        }
      })
    },
  },
})
