import './style.less'
import type { WalletMeta } from '@particle-network/connect'
import { WalletLogin } from '../../index'
import IMG_CLOSE from '../../assets/img/close.png'
import type { Langs, LoginModalOptions, SocialOption } from '../../interface'
// import { messages } from '../../i18n'
import { parseString } from '../../utils'
import BTM_LOGO from '../../assets/img/btmLogo.png'
import metamaskLeft from './../../assets/img/MetaMask1.png'
import metamaskRight from './../../assets/img/MetaMask2.png'
import WalletConnectLeft from './../../assets/img/WalletConnect1.png'
import WalletConnectRight from './../../assets/img/WalletConnect2.png'
import WalletNameLeft from './../../assets/img/WalletName1.png'
import WalletNameRight from './../../assets/img/WalletName2.png'
import backImg from './../../assets/img/back.png'
import emailImg from './../../assets/img/signinwithemail.png'
import vectorImg from './../../assets/img/vector.png'
const ROW_BTN = 'row-btn'
// const QR_BTN = 'qr-btn'
class LoginModal extends HTMLElement {
  constructor() {
    super()
  }

  _contentTmp = ''
  loginModal: HTMLElement | null = null

  lang: Langs = 'en'
  data: LoginModalOptions | null = null

  connectedCallback() {
    // 首次被插入文档时调用
    this.loginModal = this.createDom()
    const content = this.loginModal?.querySelector('.__login-main-content')
    this._contentTmp = content!.innerHTML
    window.addEventListener('email_sended', (res: any) => {
      if (!res.detail.success) {
        const confirmBtn = document.querySelector('.__email-input-r__send')
        confirmBtn!.innerHTML = 'Continue'
        return
      }
      const loading = this.loginModal!.querySelector('.__login-main-emailBox-vectorImg')
      const emailError = this.loginModal!.querySelector('.__login-main-emailError')
      let codeArr: any = []
      this.loginModal!.querySelector('.__login-main')!.innerHTML = `
      <div class="__login-main-emailError"></div>
        <div class="__login-main-emailBox">
          <img src="${backImg}" class="__login-main-emailBox-back" />
          <div class="__login-main-emailBox-top">
            <img src="${emailImg}" class="__login-main-emailBox-emailImg" />
            <img src="${vectorImg}" class="__login-main-emailBox-vectorImg" />
          </div>
        <div class="__login-main-emailBox-title">Confirm Verification Code</div>
        <div class="__login-main-emailBox-decs">We’ve sent a verification code to</div>
        <div class="__login-main-emailBox-email"></div>
        <div class="__login-main-emailBox-inputBox">
          <div class="__login-main-emailBox-inputBox-item __login-main-emailBox-inputBox-active">
            <span>|</span>
          </div>
          <div class="__login-main-emailBox-inputBox-item"></div>
          <div class="__login-main-emailBox-inputBox-item"></div>
          <div class="__login-main-emailBox-inputBox-item"></div>
          <div class="__login-main-emailBox-inputBox-item"></div>
          <div class="__login-main-emailBox-inputBox-item"></div>
        </div>
      </div>
      `
      const back = this.loginModal?.querySelector('.__login-main-emailBox-back')
      const emailBox = this.loginModal?.querySelector('.__login-main-emailBox')
      emailBox?.classList.add('__login-main-show')
      content?.classList.add('__login-main-hide')
      this.loginModal!.querySelector('.__login-main-emailBox-email')!.innerHTML = res.detail.email
      document.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || codeArr.length === 6) {
          console.log('验证code', res.detail.email, codeArr.join(''))
          WalletLogin.getInstance().loginByEmail(res.detail.email, codeArr.join(''))
          loading?.classList.add('__login-main-emailBox-show')
          emailError?.classList.remove('__login-main-emailBox-show')
          return false
        }
        // 正则数字或者字母
        if (/^[a-zA-Z0-9]$/.test(event.key)) {
          codeArr.push(event.key)
          codeArr = codeArr.slice(0, 6)
        }
        // 如果已经在验证中，不能再删除
        else if (event.key === 'Backspace' && codeArr.length > 0 && !loading?.classList.contains('__login-main-emailBox-show')) {
          codeArr.pop()
          codeArr = codeArr.slice(0, 6)
        }
        // 根据codeArr.length 设置样式 && 设置值
        const inputBox = this.loginModal!.querySelector('.__login-main-emailBox-inputBox')
        const inputBoxItem = inputBox!.querySelectorAll('.__login-main-emailBox-inputBox-item')
        inputBoxItem.forEach((item, index) => {
          if (codeArr[index]) {
            item.innerHTML = codeArr[index]
            item.classList.add('__login-main-emailBox-inputBox-active')
            if (index < 5) {
              inputBoxItem[index + 1].innerHTML = '<span>|</span>'
              inputBoxItem[index + 1].classList.add('__login-main-emailBox-inputBox-active')
            }
          }
          else if (codeArr[index] === undefined && !codeArr[index - 1]) {
            item.innerHTML = ''
            item.classList.remove('__login-main-emailBox-inputBox-active')
            inputBoxItem[index].innerHTML = ''
            inputBoxItem[index].classList.remove('__login-main-emailBox-inputBox-active')
          }
        })
        // 如果codeArr.length === 0 设置第一个为active
        if (codeArr.length === 0) {
          inputBoxItem[0].classList.add('__login-main-emailBox-inputBox-active')
          inputBoxItem[0].innerHTML = '<span>|</span>'
        }
      })

      back?.addEventListener('click', () => {
        this.loginModal!.querySelector('.__login-main')!.innerHTML = `<div class="__login-main-content">${this._contentTmp}</div>`
        // 判断 如果有 多个 __email-input-root 就只保留一个
        const emailInputRoot = this.loginModal!.querySelectorAll('.__email-input-root')
        if (emailInputRoot.length > 1) {
          for (let i = 1; i < emailInputRoot.length; i++) {
            emailInputRoot[i].remove()
          }
        }
        emailBox?.remove()
      })
    })

    window.addEventListener('email_login', (res: any) => {
      const loading = this.loginModal!.querySelector('.__login-main-emailBox-vectorImg')
      const emailError = this.loginModal!.querySelector('.__login-main-emailError')
      if (res.detail.success) {
        // 关闭弹窗
        this.loginModal?.remove()
      }
      else {
        // 验证失败
        loading!.classList.remove('__login-main-emailBox-show')
        emailError!.classList.add('__login-main-emailBox-show')
        emailError!.innerHTML = res.detail.msg
      }
    })
  }

  //

  disconnectedCallback() {
    // dom被删除时调用
  }

  adoptedCallback() {
    // dom被移动到新文档时调用
  }

  static get observedAttributes() {
    return ['lang', 'data'] // 监听config
  }

  attributeChangedCallback(name: string, oldValue: any, newValue: any) {
    // 自身属性被增删改查时调用
    let res = newValue
    try {
      res = JSON.parse(res)
    }
    catch (error) { }
    if (this[name] !== res) {
      this[name] = res
    }
  }

  get msg() {
    return WalletLogin.getInstance().i18nMap
  }

  createDom() {
    const appHtml = this.createAppHtml()
    const socialHtml = this.createSocialHtml()
    const mobileHtml = this.createMobileHtml()
    const emailHtml = this.createEmailHtml()
    const { isShowSocialConnect, isShowMobileConnect, isShowEmailConnect, isShowWallet } = WalletLogin.getInstance()
    const name = WalletLogin.getInstance()._loginConfig.getSignName()
    console.log(this.msg.title)
    // </div>
    const html = `
      <div class="__login-main ${name === 'dFans' ? '__login-dfans' : ''}">
        <div class="__login-main-content">
          <div class="__login-main-title ${name === 'dFans' ? '__login-main-dfans-title' : ''}">
            ${name === 'dFans' ? 'Log in with your wallet' : `${this.msg.title}${name}${isShowWallet ? this.msg.titleSuffix : this.msg.titleSuffix1}`}
          </div>
          <img src="${IMG_CLOSE}"  class="__login-main-close-btn" />
          <div class="__login-main-decs ${name === 'dFans' ? '__login-main-dfans-decs' : ''}"> ${name === 'dFans' ? 'More Private. More Secure. More Lucrative' : this.msg.decs}</div>
            ${isShowEmailConnect ? emailHtml : ''}
            ${isShowWallet ? appHtml : ''}
            ${(isShowSocialConnect || isShowMobileConnect) && isShowWallet
        ? `<div class="__login-main-hr">
                <div class="${name === 'dFans' ? '__login-main-hr-dfans-line' : ''}"></div>
                <span class="${name === 'dFans' ? '__login-main-hr-dfans-text' : ''}">${name === 'dFans' ? 'Get your first wallet' : `${this.msg.logInWith}`}</span>
                <div class="${name === 'dFans' ? '__login-main-hr-dfans-line' : ''}"></div>
              </div>`
        : ''}
          ${isShowSocialConnect ? socialHtml : '<div style=\'height:10px\'></div>'}
          ${isShowMobileConnect ? mobileHtml : '<div style=\'height:10px\'></div>'}
        </div>
      </div>
    `
    const el = document.createElement('div')
    el.setAttribute('class', '__login-modal')
    el.innerHTML = html
    this.append(el)

    // 直接连接
    const btns = document.querySelectorAll(`.${ROW_BTN}`)
    for (let i = 0; i < btns.length; i++) {
      const item = btns[i]
      item.addEventListener('click', (e) => {
        const row = item.getAttribute('data-row')
        WalletLogin.getInstance().connect(parseString(row))
      })
    }

    // 关闭
    const closeBtn = document.querySelector('.__login-main-close-btn')
    closeBtn?.addEventListener('click', (e) => {
      WalletLogin.getInstance().closeModal()
      document.body.style.overflow = 'auto'
    })
    return el
  }

  isQRCode(name: string): boolean {
    return ['WalletConnect'].includes(name)
  }

  setIcon(id: string) {
    let obj: any = {}
    id = id.toLocaleLowerCase()

    switch (id) {
      case 'metamask':
        obj = {
          icon: [metamaskLeft, metamaskRight],
          className: '__login-btn-metamask',
        }
        break
      case 'walletconnect_v2':
        obj = {
          icon: [WalletConnectLeft, WalletConnectRight],
          className: '__login-btn-web3modal',
        }
        break
      default:
        obj = {
          icon: [WalletNameLeft, WalletNameRight],
          className: '__login-btn-WalletName',
        }
        break
    }
    return obj
  }

  createAppHtml() {
    let res = ''
    this.data?.app.forEach((t: WalletMeta) => {
      const { name, id } = t
      const _name = name === 'Web3Modal' ? 'WalletConnect' : name
      // const btnClass = ROW_BTN
      res += `
       <div
          data-row=${JSON.stringify(t)}
          class="${ROW_BTN} ${this.setIcon(id).className} __login-item __btn-dynamic"
       >
        <img src="${this.setIcon(id).icon[0]}" class="__login-item-img"/>
        <div class="__login-item-b">${_name}</div>
        <img src="${this.setIcon(id).icon[1]}" class="__login-item-imgRight"/>
      </div>
      `
    })
    return `
          <div class="__login-main-app">
          ${res}
          </div>
    `
  }

  isPhoneItem(row: { preferredAuthType: string }) {
    return row.preferredAuthType === 'phone'
  }

  isSocialItem(row: any) {
    return row.preferredAuthType !== 'phone'
  }

  createSocialHtml() {
    let res = ''
    this.data?.social.filter(this.isSocialItem).forEach((t: SocialOption) => {
      const { icon, name } = t
      const name1 = WalletLogin.getInstance()._loginConfig.getSignName()
      res += `
       <div
          data-row=${JSON.stringify(t)}
          class="${ROW_BTN} __login-social-item __btn-dynamic ${name1 === 'dFans' ? '__login-social-item-dfans' : ''}"
        >
          <img src="${icon}" class="__login-social-item-img" />
          <div class="__login-item-b">${name}</div>
        </div>
      `
    })
    return `
    <div class="__login-main-app small">

      <div class="__login-social">${res}</div>
    </div>
    `
  }

  createMobileHtml() {
    const name = WalletLogin.getInstance()._loginConfig.getSignName()
    const logo = name === 'dFans' ? `<img src="${BTM_LOGO}" class="__login-btm-logo">` : ''
    return `
    <div class="__login-main-app small">
      <phone-input lang="${this.lang}"></phone-input>
      ${logo}
    </div>
    `
  }

  createEmailHtml() {
    return `
    <div class="__login-main-app small">
      <email-input lang="${this.lang}"></email-input>
      <div class="__login-main-hr w-full mb-3">
        <div class="__login-main-hr-email-line"></div>
        <span class="__login-main-hr-email-text">Or continue with a wallet</span>
        <div class="__login-main-hr-email-line"></div>
      </div>
    </div>
    `
  }
}

window.customElements.define('login-modal', LoginModal)
