import './style.less'
import '../../assets/css/flag.css'
import type { Langs } from '../../index'
import { WalletLogin } from '../../index'
class EmailInput extends HTMLElement {
  constructor() {
    super()
  }

  lang: Langs = 'en'
  rootEl: HTMLElement | null = null
  ulEl: HTMLElement | null = null
  inputEl: HTMLInputElement | null = null
  el: HTMLElement | null = null

  defaultCountry: any = []

  connectedCallback() {
    this.el = this.createDom() || null
  }

  disconnectedCallback() {
    // dom被删除时调用
  }

  adoptedCallback() {
    // dom被移动到新文档时调用
  }

  static get observedAttributes() {
    return ['lang'] // 监听config
  }

  attributeChangedCallback(name: string, oldValue: any, newValue: any) {
    // 自身属性被增删改查时调用
    console.log(name, oldValue, newValue)
    let res = newValue
    try {
      res = JSON.parse(res)
    }
    catch (error) { }
    if (this[name] !== res) {
      this[name] = res
    }
  }

  get msg() {
    return WalletLogin.getInstance().i18nMap
  }

  getFlag(c: string) {
    return `flag:${c.toUpperCase()}`
  }

  createDom() {
    this.rootEl = document.createElement('div')
    this.rootEl.setAttribute('class', '__email-input-root')
    const main = document.createElement('div')
    main.setAttribute('class', '__email-input')
    main.innerHTML = `
      <div class="__email-input-r">
        <div class='__email-input-r__input-box'>
          <input class='__email-input-r__input' placeholder="Enter your email"/>
        </div>
        <div class="__email-input-r__send">
          ${this.msg.continue}
        </div>
      </div>
    `
    const errorMsg = document.createElement('div')
    errorMsg.setAttribute('class', '__email-input-errorMsg')
    errorMsg.innerHTML = 'Please enter a valid email address'
    this.rootEl.append(main)
    this.append(this.rootEl)
    this.append(errorMsg)
    this.inputEl = document.querySelector('.__email-input-r__input')
    const errorMsgEl = document.querySelector('.__email-input-errorMsg')
    const confirmBtn = document.querySelector('.__email-input-r__send')
    this.inputEl?.addEventListener('focus', () => {
      setTimeout(() => {
        this.inputEl?.scrollIntoView()
      }, 400)
    })
    confirmBtn?.addEventListener('click', () => {
      // 邮箱正则验证
      if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.inputEl?.value || '') || !this.inputEl?.value) {
        this.rootEl?.classList.add('__email-input-validate-error')
        errorMsgEl?.classList.add('__email-input-show')
        return
      }
      this.rootEl?.classList.remove('__email-input-validate-error')
      errorMsgEl?.classList.remove('__email-input-show')
      if (confirmBtn.innerHTML.trim() !== 'Continue')
        return
      this.inputEl?.blur()
      WalletLogin.getInstance().sendEmailCode(this.inputEl?.value || '')
      // confirmBtn 移除 confirmBtn 点击事件
      confirmBtn.innerHTML = 'Sending...'
    })
    // 获取 input 焦点
    this.inputEl?.addEventListener('focus', () => {
      this.rootEl?.classList.add('__email-input-focus')
      // 内容为空清除错误提示
      if (!this.inputEl?.value) {
        this.rootEl?.classList.remove('__email-input-validate-error')
        errorMsgEl?.classList.remove('__email-input-show')
      }
    })
    // 失去 input 焦点
    this.inputEl?.addEventListener('blur', () => {
      this.rootEl?.classList.remove('__email-input-focus')
      // 邮箱正则验证
      if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.inputEl?.value || '') || !this.inputEl?.value) {
        this.rootEl?.classList.add('__email-input-validate-error')
        errorMsgEl?.classList.add('__email-input-show')
      }
      else {
        this.rootEl?.classList.remove('__email-input-validate-error')
        errorMsgEl?.classList.remove('__email-input-show')
      }
    })
    return this.rootEl
  }
}

window.customElements.define('email-input', EmailInput)
