import './style.less'
import type {
  Chain,
  ConnectConfig,
  Provider,
  WalletMeta,
} from '@particle-network/connect'
import {
  ParticleConnect,
  getInjectedProvider,
} from '@particle-network/connect'
import EventEmitter from 'eventemitter3'
import type { OpenBuyOptions } from '@particle-network/auth'
import * as CHAINS_LIST_BY_PARTICLE from '@particle-network/common'
import { SOCIAL_OPTIONS, getDefaultConfig } from '../config'
import type {
  AutoConnectByCache,
  ConnectReturn,
  EventNames,
  Events,
  I18nMap,
  LoginConfig,
  LoginModalOptions,
  WalletLoginConfig,
  WalletLoginProps,
} from '../interface'
import type { SocialOption } from '../index'
import { InjectWeb3, LoadingView, coreProps, customI18n } from '../index'
import { getSignText, isMobile, loop } from '../utils'
import { messages } from '../i18n'
function getI18nMessages() {
  const res: I18nMap = {}
  for (const key in messages) {
    res[key] = {
      ...messages[key],
      ...(customI18n[key] || {}),
    }
  }
  return res
}

type CONNECT_TYPE = 'social' | 'app'

const PARTICLE_ID = 'particle' // 只有google facebook的才是particle id
const WALLET_CONNECT_ID = 'walletconnect_v2'
const DEFAULT_LANG = 'en'
export class WalletLogin extends EventEmitter<Events> {
  static instance: WalletLogin
  static readonly CHAINS_LIST_BY_PARTICLE: any = CHAINS_LIST_BY_PARTICLE
  particleInstance: ParticleConnect
  web3Instance: any = null //  Web3  因为web3 太大 改成cdn了 所以从外部导入 window.web3
  isAppConnect = false // 是否为app连接(metamask, imtoken等) :
  isSocialConnect = false //  google facebook 等第三方连接: false
  isShowSocialConnect = true
  isShowMobileConnect = true
  isShowEmailConnect = false
  isShowWallet = true
  telWhitelist: [] = []
  _loadingInstance: LoadingView = LoadingView.getInstance()
  _provider: any = null
  _loginConfig: LoginConfig
  _logout: Function = () => { }
  _isDev = false
  _isAutoConnectByCache: AutoConnectByCache = async () => true // 默认 自动登录 如果有缓存
  _config: WalletLoginConfig
  _getI18nLocale: () => string = () => 'en'
  _currentOpt: any
  _currentInfo: ConnectReturn = {
    userInfo: null,
    provider: null,
    signature: null,
    random: null,
    signName: null,

  }

  _i18nMessages: I18nMap = {}
  isLogin = false
  isMatchNetwork = true // 当前网路是否匹配
  address = '' // 当前地址
  options: WalletMeta[] = []
  injected: WalletMeta | undefined
  modalOptions: LoginModalOptions = {
    app: [],
    social: [],
  } // modal options

  _emailAsync: WalletLoginProps['emailAsync'] | undefined
  _emailLoginAsync: WalletLoginProps['emailLoginAsync'] | undefined
  constructor(props: WalletLoginProps) {
    super()
    this._i18nMessages = getI18nMessages()
    const {
      config,
      loginConfig,
      logout,
      isDev,
      isAutoConnectByCache,
      on,
      getI18nLocale,
      isShowSocialConnect,
      isShowMobileConnect,
      isShowEmailConnect,
      isShowWallet,
      telWhitelist,
      emailAsync,
      emailLoginAsync,
    } = props
    this._emailAsync = emailAsync
    this._emailLoginAsync = emailLoginAsync
    this.isShowSocialConnect = isShowSocialConnect ?? this.isShowSocialConnect
    this.isShowMobileConnect = isShowMobileConnect ?? this.isShowMobileConnect
    this.isShowEmailConnect = isShowEmailConnect ?? this.isShowEmailConnect
    this.isShowWallet = isShowWallet ?? this.isShowWallet
    this.telWhitelist = telWhitelist || []
    this._config = getDefaultConfig(isDev, loginConfig.getSignName())
    const particleConfig: ConnectConfig = {
      ...this._config.connect,
      ...(config || {}),
    }
    this._getI18nLocale = getI18nLocale ?? this._getI18nLocale
    this._isDev = isDev
    this._isAutoConnectByCache
      = isAutoConnectByCache ?? this._isAutoConnectByCache
    this.particleInstance = new ParticleConnect(particleConfig)
    this.particleInstance.particle.setAuthTheme({ uiMode: 'dark' })

    // 初始化函数
    this._loginConfig = loginConfig
    this._logout = logout || this._logout

    // 初始化options
    this.options = this.particleInstance.walletMetas()
    this.injected = this.options.find(
      t => t.name === getInjectedProvider()?.name,
    )
    const walletConnectOptions = this.options.find(
      t => t.id === WALLET_CONNECT_ID,
    )
    this.modalOptions = {
      app: [
        ...(this.injected ? [this.injected] : []),
        {
          ...walletConnectOptions!,
        },
      ],
      social: SOCIAL_OPTIONS,
    }

    // console.log(this.options, this.modalOptions)
    this.initOn(on)
    this.checkCache()
  }

  static getInstance() {
    if (!WalletLogin.instance) {
      WalletLogin.instance = new WalletLogin(coreProps)
    }
    return WalletLogin.instance
  }

  get showConfirmSign(): boolean {
    return [WALLET_CONNECT_ID].includes(this._currentOpt?.id) && isMobile()
  }

  get lang() {
    return this._getI18nLocale() || DEFAULT_LANG
  }

  get i18nMap() {
    return this._i18nMessages[this.lang]
  }

  get provider() {
    return this._provider
  }

  set provider(p) {
    this._provider = p
    this.handleProviderChanged(p)
    this.initWeb3()
  }

  get loadingText(): { title: string; content: string } {
    if (this.isLogin) {
      return {
        title: this.i18nMap.switchAccount,
        content: this._currentOpt?.preferredAuthType === 'phone' ? '' : this.i18nMap.signInContent,
      }
    }
    else {
      return {
        title: this.i18nMap.signIn,
        content: this._currentOpt?.preferredAuthType === 'phone' ? '' : this.i18nMap.signInContent,
      }
    }
  }

  initOn(on: Events | undefined) {
    for (const key in on) {
      this.on(key as EventNames, on[key])
    }
  }

  setConnectType(type: CONNECT_TYPE) {
    this.isAppConnect = type === 'app'
    this.isSocialConnect = type === 'social'
  }

  async checkCache() {
    try {
      const NO_AUTO_LOGIN_ID = ['walletconnect']
      const bol = await this._isAutoConnectByCache()
      const cacheId: string | null | undefined
        = this.particleInstance.cachedProviderId()
      console.info('cache provider id', cacheId, bol)
      if (bol && cacheId) {
        if (NO_AUTO_LOGIN_ID.includes(cacheId)) {
          await this._logout()
          return
        }
        const { getSignName } = this._loginConfig
        this._currentInfo.signName = getSignName()
        const p = await this.particleInstance.connectToCachedProvider()

        if (cacheId === PARTICLE_ID) {
          this.setConnectType('social')
        }
        else {
          this.setConnectType('app')
        }
        this.provider = p
        this.setCurrentAddress()
        this.isLogin = true
      }
      else {
        await this._logout()
      }
    }
    catch (error) {
      console.error(error)
    }
  }

  initWeb3() {
    window.web3 = new InjectWeb3(this.provider)
    this.web3Instance = window.web3
    this.subscribeProvider()
  }

  async setCurrentAddress(): Promise<string | undefined> {
    try {
      const accounts = await this.web3Instance!.eth.getAccounts()
      if (accounts.length === 0) {
        return Promise.reject()
      }
      this.address = this.parseAddress(accounts[0])
      return this.address
    }
    catch (error) {
      console.error(error)
    }
  }

  parseAddress(str = '') {
    return str.toLowerCase()
  }

  subscribeProvider(p: any = this.provider) {
    this.particleInstance.removeAllListeners()
    this.particleInstance.on('accountsChanged', async (e: string[]) => {
      console.log('wallet login accountsChanged ===> ', e)
      if (e.length) {
        const _address = this.parseAddress(e[0])
        if (_address !== this.address) {
          this.address = _address
          this.handleAccountsChanged(e)
          this._loadingInstance.open({
            ...this.loadingText,
          })
          try {
            const { getRandom } = this._loginConfig!
            this._currentInfo.random = await getRandom(this.address)
            await this.checkSign()
            this.isLogin = true
          }
          catch (error) {
            this.handleError(error)
          }
          finally {
            this._loadingInstance.close()
          }
        }
      }
      else {
        this.handleDisconnect()
      }
    })
    this.particleInstance.on('chainChanged', (chain: Chain | undefined) => {
      console.info('wallet login chainChanged ===>', chain)
      if (!chain) {
        return
      }
      const chainId: number = +chain.id
      const message = this.checkChain(chainId)
      this.handleChainChanged(message, chainId, chain)
    })
    this.particleInstance.on('disconnect', () => {
      console.info('wallet login disconnect ===>')
      this.handleDisconnect()
    })
    this.particleInstance.on('connect', (e: Provider) => {
      console.info('wallet login connect ===>', e)
    })
    this.particleInstance.on('message', (e: any) => {
      console.info('wallet login message ===>', e)
    })
  }

  async connect(opt: SocialOption | any): Promise<ConnectReturn> {
    // window.localStorage.clear() // 清空缓存 每次都弹出扫码弹窗
    Object.keys(localStorage).forEach(function(key) {
      if (!['userCode', 'pn_device_id','wc@2:core:0.3//keychain','sellerName','avatar','domain','W3M_VERSION','particle_connect_cached_provider','shop','shopCode','seller','RCDeviceId'].includes(key)) {
        localStorage.removeItem(key);
      }
    })
    try {
      this.handleLoginStart()
      this._currentOpt = opt
      console.info('connect option => ', opt)
      if (opt.isSocial) {
        this._currentInfo.provider = await this.particleInstance.connect(
          'particle',
          {
            ...opt,
            hideLoading: false,
          },
        )
        console.log('11111=> ', this.particleInstance.particle.auth.userInfo())
        const { thirdparty_user_info, uuid, token }
          = this.particleInstance.particle.auth.userInfo() || {}
        this._currentInfo = {
          ...this._currentInfo,
          signByParticle: {
            uuid, token, type: 1,
          },
          userInfo: thirdparty_user_info?.user_info,
        }
        this.setConnectType('social')
      }
      else {
        this._currentInfo.provider = await this.particleInstance.connect(
          opt.id as any,
          {
            hideLoading: false,
          },
        )
        this.setConnectType('app')
      }
      this.provider = this._currentInfo.provider

      await this.setCurrentAddress()
      this._loadingInstance.open({
        ...this.loadingText,
      })
      const { getRandom, getSignName, login } = this._loginConfig!
      this._currentInfo.signName = getSignName()
      // 不进行签名 直接登录
      if (opt.skipSign) {
        if (opt.isSocial) {
          this._currentInfo.random = await getRandom(this.address)
          await this.checkSign()
        }
        else {
          await login(this, this._currentInfo)
        }
      }
      else {
        this._currentInfo.random = await getRandom(this.address)
        await this.checkSign()
      }

      this.closeModal()
      this.isLogin = true
      return Promise.resolve(this._currentInfo)
    }
    catch (error) {
      this.handleError(error)
      return Promise.reject(error)
    }
    finally {
      this._loadingInstance.close()
      this.hideParticleModal()
      this.handleLoginFinish()
    }
  }

  async checkSign() {
    try {
      const { login } = this._loginConfig!
      this._currentInfo.signature = null
      if (this.showConfirmSign) {
        this._loadingInstance.update({
          title: this.i18nMap.approvePopUp,
          content: this.i18nMap.approvePopUpMsg,
          btn: this.i18nMap.continue,
          showBtn: true,
        })

        await loop(() => !!this._currentInfo.signature)
        this._loadingInstance.update({
          title: this.i18nMap.signIn,
          showBtn: false,
        })
      }
      else {
        const signature = await getSignText(
          this.web3Instance,
          this.address,
          this._currentInfo.random,
          this._currentInfo.signName!,
        )
        this._currentInfo.signature = signature as string
      }
      return login(this, this._currentInfo)
    }
    catch (error) {
      return Promise.reject(error)
    }
  }

  async continueSign() {
    try {
      const signature = await getSignText(
        this.web3Instance,
        this.address,
        this._currentInfo.random,
        this._currentInfo.signName!,
      )
      this._currentInfo.signature = signature as string
    }
    catch (error) {
      console.error(error)
    }
  }

  // openMiniMode() {
  //   document.querySelector("body")?.classList.add("mini-login-form");
  // }

  // closeMiniMode() {
  //   document.querySelector("body")?.classList.remove("mini-login-form");
  // }

  checkChain(chainId: number): string {
    let res = ''
    const {
      env: { id },
    } = this._config
    if (this._isDev && chainId !== id) {
      res = this.i18nMap.netWorkErrorByDev
    }
    if (!this._isDev && chainId !== id) {
      res = this.i18nMap.netWorkErrorByProd
    }
    this.isMatchNetwork = !res
    return res
  }

  async logout() {
    this._loadingInstance.open({
      title: this.i18nMap.logout,
    })

    try {
      this.hideParticleModal()
      this.clearCache()
      await this.particleInstance.disconnect()
      return await this._logout()
    }
    catch (error) {
      console.error(error)
      window.location.reload()
    }
    finally {
      this._loadingInstance.close()
    }
  }

  async logoutBySilent() {
    this.hideParticleModal()
    await this.particleInstance.disconnect()
    return await this._logout()
  }

  // ------------------ error -----------------------
  handleError(error: any) {
    error && this.emit('error', error?.message || error)
  }

  handleDisconnect() {
    this.emit('disconnect')
  }

  handleAccountsChanged(e: string[]) {
    this.emit('accountsChanged', e)
  }

  handleChainChanged(message: string, chainId: number, chain: Chain) {
    this.emit('chainChanged', message, chainId, chain)
  }

  // 发送邮件验证码
  async sendEmailCode(email: string) {
    if (email) {
      const res = await this._emailAsync?.(email)
      window.dispatchEvent(
        new CustomEvent('email_sended', {
          detail: res,
        }),
      )
    }
  }

  // 邮箱登录
  async loginByEmail(email: string, code: string) {
    if (email && code) {
      const res = await this._emailLoginAsync?.({ email, code })
      window.dispatchEvent(
        new CustomEvent('email_login', {
          detail: res,
        }),
      )
    }
  }

  handleLoginStart() {
    this.emit('loginStart')
  }

  handleLoginFinish() {
    this.emit('loginFinish')
  }

  handleProviderChanged(e: any) {
    this.emit('providerChanged', e)
  }
  // ------------------ modal -----------------------

  checkInjected(): boolean {
    if (!isMobile()) {
      return false
    }
    if (this.injected) {
      this.connect(this.injected)
      return true
    }
    return false
  }

  openWallet(target?: string | undefined, features?: string | undefined): void {
    this.particleInstance.particle.openWallet(target, features)
  }

  clearModal() {
    const els = document.querySelectorAll('login-modal')
    for (let i = 0; i < els.length; i++) {
      els[i].remove()
    }
  }

  clearCache() {
    localStorage.removeItem('particle_connect_cached_provider')
    localStorage.removeItem('walletconnect')
    localStorage.removeItem(`pn_auth_user_info_${this._config.connect.appId}`)
  }

  openModal() {
    const { getSignName } = this._loginConfig
    this._currentInfo.signName = getSignName()
    if (this.checkInjected()) {
      return
    }
    this.clearCache()
    this.clearModal()

    const box = document.createElement('div')
    const html = `<login-modal lang="${this.lang}" data=${JSON.stringify(
      this.modalOptions,
    )}></login-modal>`
    box.innerHTML = html
    document.documentElement.append(box)
  }

  closeModal() {
    this.clearModal()
  }

  toggleParticleModal() {
    const el = document.querySelector('#particle-network-container')
    el?.classList.toggle('__show')
  }

  hideParticleModal() {
    const el = document.querySelector('#particle-network-container')
    el?.classList.remove('__show')
    el?.classList.add('__hide')
  }

  showParticleModal() {
    const el = document.querySelector('#particle-network-container')
    el?.classList.add('__show')
    el?.classList.remove('__hide')
  }

  openBuy(options?: OpenBuyOptions | undefined, target?: string | undefined, features?: string | undefined) {
    this.particleInstance.particle.openBuy(options, target, features)
  }

  // chainsInfo
  static getChainInfoById(id: number): any {
    const res = Object.values(CHAINS_LIST_BY_PARTICLE).find((item: any) => item.id === id)
    return res
  }
}
