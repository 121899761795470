import type {
  Chain,
  ConnectConfig,
} from '@particle-network/connect'
import {
  argent,
  injectedEVM,
  metaMask,
  omni,
  rainbow,
  walletconnect,
} from '@particle-network/connect'
import { ArbitrumGoerli, ArbitrumOne, Ethereum, EthereumGoerli } from '@particle-network/common'
import type { SocialOption, WalletLoginConfig, WalletLoginEnv } from '../interface'
import IMG_FACEBOOK from '../assets/img/facebook.png'
import IMG_GOOGLE from '../assets/img/google.png'

const CONFIG: {
  [key: string]: {
    connect: ConnectConfig
    env: WalletLoginEnv
  }
} = {
  DEV: {
    connect: {
      projectId: '750cfc62-8f06-43e1-b8cb-7ed270dc2278',
      clientKey: 'cQgtg4U3X3pxmOuhUG5pbVaDbCGX40aZBJoHXZzk',
      appId: 'aa00cf20-4152-4831-95ed-cec449e160f7',
      chains: [Ethereum as Chain, EthereumGoerli as Chain, ArbitrumOne as Chain, ArbitrumGoerli as Chain],
      particleWalletEntry: {
        displayWalletEntry: false,
      },
      wallets: [
        injectedEVM(),
        rainbow({ projectId: '93b164ed8794ea56cc576d308396cd59' }),
        argent({ projectId: '93b164ed8794ea56cc576d308396cd59' }),
        omni({ projectId: '93b164ed8794ea56cc576d308396cd59' }),
        metaMask({ projectId: '93b164ed8794ea56cc576d308396cd59' }),
        walletconnect({
          projectId: '93b164ed8794ea56cc576d308396cd59',
          showQrModal: true,
        }),
      ],
    },
    env: {
      id: EthereumGoerli.id,
    },
  },
  PROD: {
    connect: {
      projectId: '750cfc62-8f06-43e1-b8cb-7ed270dc2278',
      clientKey: 'cQgtg4U3X3pxmOuhUG5pbVaDbCGX40aZBJoHXZzk',
      appId: 'aa00cf20-4152-4831-95ed-cec449e160f7',
      chains: [Ethereum as Chain, EthereumGoerli as Chain, ArbitrumOne as Chain, ArbitrumGoerli as Chain],
      particleWalletEntry: {
        displayWalletEntry: false,
      },
      wallets: [
        injectedEVM(),
        rainbow({ projectId: '93b164ed8794ea56cc576d308396cd59' }),
        argent({ projectId: '93b164ed8794ea56cc576d308396cd59' }),
        omni({ projectId: '93b164ed8794ea56cc576d308396cd59' }),
        metaMask({ projectId: '93b164ed8794ea56cc576d308396cd59' }),
        walletconnect({
          projectId: '93b164ed8794ea56cc576d308396cd59',
          showQrModal: true,
        }),
      ],
    },
    env: {
      id: Ethereum.id,
    },
  },
}
const dFansConfig = {
  connect: {
    projectId: '8ce550b6-33d9-4b35-bc4b-01323b88731a',
    clientKey: 'cGzsLDwYYWy5vZnH8cwzQG8SDT38liw5VKPKVb5c',
    appId: '367eb4bb-1b0e-4d2b-951d-7f2771af50e5',
    chains: [ArbitrumOne as Chain, Ethereum as Chain, ArbitrumGoerli as Chain, EthereumGoerli as Chain],
    particleWalletEntry: {
      displayWalletEntry: false,
    },
    wallets: [
      injectedEVM(),
      rainbow({ projectId: '39e2db89e666ce75165520ed7da7ee5c' }),
      argent({ projectId: '39e2db89e666ce75165520ed7da7ee5c' }),
      omni({ projectId: '39e2db89e666ce75165520ed7da7ee5c' }),
      metaMask({ projectId: '39e2db89e666ce75165520ed7da7ee5c' }),
      walletconnect({
        projectId: '39e2db89e666ce75165520ed7da7ee5c',
        showQrModal: true,
      }),
    ],
  },
  env: {
    id: Ethereum.id,
  },
}
export function getDefaultConfig(isDev: boolean, signName?: string): WalletLoginConfig {
  const key = isDev ? 'DEV' : 'PROD'
  if (signName === 'dFans') {
    return dFansConfig
  }
  else {
    return CONFIG[key]
  }
}

export const SOCIAL_OPTIONS: SocialOption[] = [
  {
    preferredAuthType: 'facebook',
    supportAuthTypes: 'all',
    socialLoginPrompt: 'consent',
    name: 'Facebook',
    icon: IMG_FACEBOOK,
    isSocial: true,
    skipSign: true,
  },
  {
    preferredAuthType: 'google',
    supportAuthTypes: 'all',
    socialLoginPrompt: 'consent',
    name: 'Google',
    icon: IMG_GOOGLE,
    isSocial: true,
    skipSign: true,

  },
]

export function getPhoneConfig(account: string): SocialOption {
  return {
    preferredAuthType: 'phone',
    account,
    supportAuthTypes: 'all',
    socialLoginPrompt: 'consent',
    isSocial: true,
    skipSign: true,
  }
}
