import './style.less'
import '../../assets/css/flag.css'
import IMG_ARROW from '../../assets/img/arrow.png'
import type { Langs } from '../../index'
import { WalletLogin } from '../../index'
import { parseString } from '../../utils'
import { getPhoneConfig } from '../../config'
import { defaultCountriesData } from './all-countries'
const reg = /^\d{8,14}$/
// <div class="flag:US" />
interface Current {
  country: string
  prefix: string
}
class PhoneInput extends HTMLElement {
  constructor() {
    super()
  }

  lang: Langs = 'en'
  rootEl: HTMLElement | null = null
  ulEl: HTMLElement | null = null
  inputEl: HTMLInputElement | null = null
  countryBtnEl: HTMLElement | null = null
  el: HTMLElement | null = null
  _current: Current = {
    country: 'sg',
    prefix: '+65',
  }

  defaultCountry: any = []

  get current(): Current {
    return this._current
  }

  set current(v: Current) {
    this._current = v
    this.changePage()
  }

  connectedCallback() {
    // 首次被插入文档时调用
    const telWhitelist = WalletLogin.getInstance().telWhitelist
    this.defaultCountry = []
    if (telWhitelist?.length) {
      defaultCountriesData.forEach((t) => {
        const [k1, k2, v] = t
        telWhitelist.forEach((item) => {
          if (v === item) {
            this.defaultCountry.push(t)
          }
        })
      })
    }
    else {
      this.defaultCountry = defaultCountriesData
    }
    const [k1, k2, v] = this.defaultCountry?.[0] || defaultCountriesData[0]
    this._current = {
      country: k2,
      prefix: `+${v}`,
    }
    this.el = this.createDom()
  }

  disconnectedCallback() {
    // dom被删除时调用
  }

  adoptedCallback() {
    // dom被移动到新文档时调用
  }

  static get observedAttributes() {
    return ['lang'] // 监听config
  }

  attributeChangedCallback(name: string, oldValue: any, newValue: any) {
    // 自身属性被增删改查时调用
    console.log(name, oldValue, newValue)
    let res = newValue
    try {
      res = JSON.parse(res)
    }
    catch (error) { }
    if (this[name] !== res) {
      this[name] = res
    }
  }

  get msg() {
    return WalletLogin.getInstance().i18nMap
  }

  getFlag(c: string) {
    return `flag:${c.toUpperCase()}`
  }

  createDom() {
    const name = WalletLogin.getInstance()._loginConfig.getSignName()
    this.rootEl = document.createElement('div')
    this.rootEl.setAttribute('class', '__phone-input-root')
    const main = document.createElement('div')
    if (name === 'dFans') {
      main.setAttribute('class', '__phone-input __phone-dfans')
    }
    else {
      main.setAttribute('class', '__phone-input')
    }

    const flag = this.getFlag(this.current.country)
    main.innerHTML = `
      <div class="__phone-input-l ${name === 'dFans' ? '__phone-input-dfans' : ''}">
        <div class="__phone-input-l__country">
          <div class="country-flag ${flag}"></div>
        </div>
        <img src="${IMG_ARROW}" class="__phone-input-l__arrow"/>
      </div>

      <div class="__phone-input-r ${name === 'dFans' ? '__phone-input-dfans' : ''}">
        <div class="__phone-input-r__prefix">
          ${this.current.prefix}
        </div>
        <div class='__phone-input-r__input-box'>
          <input class='__phone-input-r__input' placeholder="Mobile number" type="number"/>
        </div>

        <div class="${name === 'dFans' ? '__phone-input-r__send-dfans' : '__phone-input-r__send'}">
          ${this.msg.send}
        </div>
      </div>
    `
    this.rootEl.innerHTML = `<ul class="__phone-input-ul" >
        ${this.createLis()}
    </ul>`

    this.rootEl.append(main)

    this.append(this.rootEl)
    this.countryBtnEl = document.querySelector('.__phone-input-l')
    this.inputEl = document.querySelector('.__phone-input-r__input')
    this.ulEl = document.querySelector('.__phone-input-ul')
    let confirmBtn : any= null
    if (name === 'dFans') {
      confirmBtn = document.querySelector('.__phone-input-r__send-dfans')
    }
    else {
      confirmBtn = document.querySelector('.__phone-input-r__send')
    }
    this.inputEl?.addEventListener('focus', () => {
      setTimeout(() => {
        this.inputEl?.scrollIntoView()
      }, 400)
    })
    confirmBtn?.addEventListener('click', () => {
      if (this.current.prefix === '+86' && this.inputEl?.value.length !== 11) {
        alert('请输入11位手机号！')
        return
      }
      if (this.current.prefix === '+65' && this.inputEl?.value.length !== 8) {
        alert('请输入8位手机号！')
        return
      }
      // @ts-expect-error
      if (this.inputEl.value.length < 7) {
        return
      }
      this.inputEl?.blur()
      const account = this.current.prefix + this.inputEl?.value
      const config = getPhoneConfig(account)
      WalletLogin.getInstance().connect(config)
    })

    const toggleUl = () => {
      this.checkPosition()
      this.ulEl?.classList.toggle('active')
    }

    this.ulEl?.addEventListener('click', (e: any) => {
      const tags = [
        e.target,
        e.target.parentNode,
        e.target.parentNode.parentNode,
      ]
      const target = tags.find(t => t.tagName.toLowerCase() === 'li')
      if (target) {
        const row = target.getAttribute('data-row')
        this.current = parseString(row)
        toggleUl()
      }
    })
    this.countryBtnEl?.addEventListener('click', (e) => {
      toggleUl()
    })
    window.addEventListener('resize', (e) => {
      this.checkPosition()
    })
    return this.rootEl
  }

  checkPosition() {
    const { height: ulElHeight } = this.ulEl!.getBoundingClientRect()
    const { y, height } = this.getBoundingClientRect()
    const pageHeight = document.documentElement.clientHeight || document.body.clientHeight
    if (pageHeight - y - height > ulElHeight) {
      this.ulEl!.style.cssText = `
        bottom:-${ulElHeight}px;
        top:unset
        `
    }
    else {
      this.ulEl!.style.cssText = `
        top:-${ulElHeight}px;
        bottom:unset
        `
    }
  }

  createLis() {
    let res = ''

    this.defaultCountry.forEach((t) => {
      const [k1, k2, v] = t
      const obj = {
        country: k2,
        prefix: `+${v}`,
      }
      res += `
      <li class="__phone-input-ul__li" data-row=${JSON.stringify(obj)}>
        <div class="__phone-input-ul__li-l">
          <div class="_flag">
            <span class=" ${this.getFlag(k2)}"></span>
          </div>
          <div class="_country">${k1}</div>
         </div>
        <div class="__phone-input-ul__li-r">+${v}</div>
      </li>
      `
    })

    return res
  }

  changePage() {
    const { country, prefix } = this.current
    const flag = document.querySelector('.country-flag')
    flag?.setAttribute('class', `country-flag ${this.getFlag(country)}`)
    const pre = document.querySelector('.__phone-input-r__prefix')
    pre!.innerHTML = prefix || ''
  }
}

window.customElements.define('phone-input', PhoneInput)
