import { ISeller, ISellerState, IShop } from '@/interface'
import { defineStore } from 'pinia'
import { uniqWith, isEqual } from 'lodash-es'
export const useSellerStore = defineStore('seller', {
  state: (): ISellerState => {
    return {
      info: null, //  每次都拉新买家数据
      shop: null, // shop 数据同理
      musicList: [],
      playUrl: '',
    }
  },
  actions: {
    SET_SELLER(data: ISeller) {
      this.info = data
    },
    CLEAR_SELLER() {
      this.info = null
    },
    SET_SHOP(data: IShop) {
      this.shop = data
    },
    CLEAR_SHOP() {
      this.shop = null
    },
    CLEAR_SELLER_ALL() {
      this.CLEAR_SELLER()
      this.CLEAR_SHOP()
    },
    SET_MUSIC_LIST(data) {
      this.musicList.push(data)
      this.musicList = uniqWith(this.musicList, isEqual)
    },
    PLAY(data) {
      this.playUrl = data.url
      console.log(this.musicList, '-play->', this.playUrl)
    },
  },
})
