import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
/**
  meta:{
    title: string;
    isKeepAlive: boolean; // 是否需要缓存 默认false
    hideNav: boolean; // 是否需要导航 默认false
    isOpacity: boolean // top nav 透明
    isBack: boolean // 默认 false 返回 按钮
  }
**/
const routes = [
  {
    path: '/home',
    alias: '/',
    name: 'Home',
    meta: {
      title: 'Home',
      isOpacity: true,
      isKeepAlive: true,
    },
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/post',
    name: 'Post',
    meta: {
      isBack: true,
    },
    component: () => import('@/views/posts/index.vue'),
  },
  {
    path: '/collections',
    name: 'Collections',
    meta: {
      isBack: true,
    },
    component: () => import('@/views/collections/index.vue'),
  },
  {
    path: '/nfts',
    alias: '/NFT',
    name: 'NFT',
    meta: {
      isBack: true,
    },
    component: () => import('@/views/NFT/index.vue'),
  },
  {
    path: '/nfts/list',
    alias: '/nftsList',
    name: 'nftsList',
    meta: {
      isBack: true,
      isOpacity: true,
    },
    component: () => import('@/views/NFT/nftsList.vue'),
  },
  {
    path: '/collection/qtList',
    alias: '/qtList',
    name: 'qtList',
    meta: {
      isBack: true,
      isOpacity: true,
    },
    component: () => import('@/views/QtNft/index.vue'),
  },
  {
    path: '/earnings',
    name: 'Earnings',
    meta: {
      isBack: true,
    },
    component: () => import('@/views/earnings/index.vue'),
  },
  // {
  //   path: '/:catchAll(.*)',
  //   redirect: {
  //     name: '404',
  //     replace: true,
  //   },
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to, from, position) => {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    if (position) {
      return position
    }
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})
router.afterEach((to, from) => {
  NProgress.done() //页面跳转完成,关闭进度条
})

export default router
