import {
  getAddress,
  getBuyer,
  getVIP,
  setBuyer,
  setVIP,
  setAddress,
  getToken,
  setToken,
  removeToken,
  removeBuyer,
  removeAddress,
  removeVIP,
} from '@/utils/auth'
import { defineStore } from 'pinia'
import { IBuyer, IBuyerState } from '@/interface'
import { memberService } from '@/api'
export const useBuyerStore = defineStore('buyer', {
  state: (): IBuyerState => {
    return {
      info: getBuyer(),
      address: getAddress(),
      isVip: getVIP(),
      token: getToken(),
    }
  },
  getters: {
    isLogin: (state): boolean => {
      // 完全登录时  用户信息和钱包地址都存在
      return !!(state.info && state.address && state.token)
    },
  },
  actions: {
    SET_TOKEN(data: string) {
      this.token = data
      setToken(data)
    },
    CLEAR_TOKEN() {
      this.token = ''
      removeToken()
    },
    SET_BUYER(data: IBuyer) {
      this.info = data
      setBuyer(data)
    },
    CLEAR_BUYER() {
      this.info = null
      removeBuyer()
    },

    SET_ADDRESS(data: string) {
      setAddress(data)
      this.address = data
    },
    CLEAR_ADDRESS() {
      this.address = ''
      removeAddress()
    },

    SET_VIP(data: boolean) {
      setVIP(data)
      this.isVip = data
    },
    CLEAR_VIP() {
      this.isVip = false
      removeVIP()
    },

    CLEAR_BUYER_ALL() {
      this.CLEAR_TOKEN()
      this.CLEAR_BUYER()
      this.CLEAR_ADDRESS()
      this.CLEAR_VIP()
    },

    GET_VIP(): Promise<void> {
      return new Promise((resolve, reject) => {
        if (!this.isLogin) {
          return resolve()
        }
        memberService
          .getCategory()
          .then((res: any) => {
            this.SET_VIP(res.price > 0)
            resolve()
          })
          .catch(err => {
            resolve()
          })
      })
    },
  },
})
